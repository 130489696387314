import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/api/get-subscriptions": [20],
		"/api/newsletter-klaviyo": [21],
		"/api/newsletter-mailchimp": [22],
		"/[lang]/(ecom)/forgot-password": [4,[2]],
		"/[lang]/(ecom)/login": [5,[2]],
		"/[lang]/(ecom)/orders": [~7,[2]],
		"/[lang]/(ecom)/order/[id]": [~6,[2]],
		"/[lang]/playground": [19,[2]],
		"/[lang]/(ecom)/profile": [~8,[2]],
		"/[lang]/(ecom)/reset-password": [9,[2]],
		"/[lang]/(ecom)/settings": [~10,[2]],
		"/[lang]/(ecom)/signup-activate": [12,[2]],
		"/[lang]/(ecom)/signup-success": [13,[2]],
		"/[lang]/(ecom)/signup-verify": [14,[2]],
		"/[lang]/(ecom)/signup": [11,[2]],
		"/[lang]/(ecom)/subscriptions": [~15,[2]],
		"/[lang]/[_jobs=jobs]/[slug]": [18,[2]],
		"/[lang]/[_blog=blog]/[slug]": [17,[2]],
		"/[lang]/(ecom)/[_shop=shop]/[[slug]]": [3,[2]],
		"/[lang]/[...path]": [16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';